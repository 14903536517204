import React, {useEffect, useState} from 'react';

const defaults = {
  campaignNm: 'General',
  dealerNm: 'VW 24-Hour Roadside Assistance',
  dealerAdr: 'P.O. Box 9145',
  dealerCty: 'Medford',
  dealerSt: 'Massachusetts',
  dealerZip: '02155',
  dealerPh: '1-800-411-6688',
};

export default (props) => {
  const [dealerData, setDealerData] = useState({
    ...defaults,
  });

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash) {
      const dealerParts = window.location.hash
        .replace('#', '')
        .replace(/\%20/g, ' ')
        .split('&');

      if (dealerParts.length === 7) {
        setDealerData({
          ...dealerData,
          campaignNm: dealerParts[0],
          dealerNm: dealerParts[1],
          dealerAdr: dealerParts[2],
          dealerCty: dealerParts[3],
          dealerSt: dealerParts[4],
          dealerZip: dealerParts[5],
          dealerPh: dealerParts[6],
        });
      }

      if (dealerParts < 7) {
        setDealerData({
          ...dealerData,
          dealerNm: finalData[0],
          dealerAdr: finalData[1],
          dealerCty: finalData[2],
          dealerSt: finalData[3],
          dealerZip: finalData[4],
          dealerPh: finalData[5],
        });
      }
    }
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const evntCat = `Click-To-Call - ${dealerData.campaignNm} Ph: ${dealerData.dealerPh}`;

    window.ga =
      window.ga ||
      function () {
        (ga.q = ga.q || []).push(arguments);
      };
    ga.l = +new Date();

    window.ga('send', {
      hitType: 'event',
      eventCategory: evntCat,
      eventAction: 'click',
      eventLabel: evntCat,
    });
  }, [dealerData]);

  return (
    <div className="dealer-information-inner">
      <h2 className="dealer">{dealerData.dealerNm}</h2>
      <p className="location">
        <span className="address">{dealerData.dealerAdr}</span>
        <span className="csz">
          {dealerData.dealerCty}, {dealerData.dealerSt} {dealerData.dealerZip}
        </span>
      </p>
      <p className="phone">
        <a href={`tel:${dealerData.dealerPh.replace(/-/g, '')}`}>
          {dealerData.dealerPh}
        </a>
      </p>
    </div>
  );
};
