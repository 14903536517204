import React from 'react';
import {Helmet} from 'react-helmet';
import GlobalStyles from '../components/global';
import {Reset} from 'styled-reset';
import {Main, Header, InformationBlock} from '../components/style';
import Dealer from '../components/Dealer';
import Logo from '../images/logo-new.svg';

const IndexPage = () => (
  <Main id="page-wrap">
    <Reset />
    <GlobalStyles />
    <Helmet>
      <meta name="viewport" content="width=device-width initial-scale=1" />
      <title>VW Customer Service</title>
      <meta
        name="description"
        content="Got a question for VW? Need some information? Feel free to contact us via phone or email. VW customer service is easy to reach, and we're here to help."
      />
      <meta
        name="viewport"
        content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, width=device-width"
      />
      {/* <link rel="icon" type="image/vnd.microsoft.icon" href="images/icons/favicon.ico" />
      <link rel="stylesheet" href="css/main.css" /> */}
    </Helmet>

    <Header>
      <h1>
        <Logo />
        <span>Contact Us</span>
      </h1>
    </Header>

    <InformationBlock className="information-block">
      <div className="dealer-information">
        <Dealer />
      </div>
    </InformationBlock>
  </Main>
);

export default IndexPage;
