import React from 'react';
import styled from 'styled-components';
import Logo from '../images/volkswagen-logo.png';
import CarIcon from '../images/icons/car-care-icon.png';

const colors = {
  'dark-slate-gray': '#3b3b3b',
  black: '#000000',
  white: '#ffffff',
  'dark-fusia': '#630f39',
  'orange-red': '#ef453c',
  'sky-blue': '#01b1ec',
};

const fonts = {
  Black: "'VWHeadWeb-Bold', Helvetica, Arial, sans-serif",
  Book: "'VWTextWeb-Regular', Helvetica, Arial, sans-serif",
  Bold: "'VWTextWeb-Bold', Helvetica, Arial, sans-serif",
  Base: 'proxima-nova',
};

const breakpoints = {
  smartphones: 'only screen and (max-width: 580px)',
  iphoneLandscape: 'only screen and (max-width: 480px)',
};

const copyStyle = (
  family,
  size,
  weight,
  color,
  lineHeight = 'normal',
  textTransform = 'none',
) => `
	font-family: ${family};
 	font-size: ${size};
 	font-weight: ${weight};
 	line-height: ${lineHeight};
 	text-transform: ${textTransform};
 	color: ${color};
`;

export const Main = styled.main`
  .intro p,
  .intro h1 {
    max-width: 555px;

    @media ${breakpoints.smartphones} {
      width: 80%;
    }
  }

	.intro {
		margin-bottom: 65px;
		display: none;

		@media ${breakpoints.smartphones} {
			margin-bottom: 35px;
		}

		p,
		h1 {
			text-align: center;
			margin: 0px auto;
			max-width: 555px;
		}


		h1 {
			${copyStyle(fonts.Black, '35px', 'normal', 'black')}

			@media ${breakpoints.smartphones} {
				margin-bottom: 10px;
				font-size: 32px;

				.mobile-break {
					display: block;
				}
			}
		}

		p {
			${copyStyle(fonts.Book, '16px', 'normal', '26px', 'black')}
		}
	}

`;

export const Header = styled.header`
  background-color: #01b1ec;
  margin-bottom: 40px;
  overflow: hidden;

  @media ${breakpoints.iphoneLandscape} {
    margin-bottom: 25px;
  }

  h1 {
    ${copyStyle(fonts.Black, '75px', 500, 'white')}
    padding: 30px 0px 48px;
    background-size: 17% auto;
    position: relative;
    margin: 0px auto;
    max-width: 100%;
    color: 'white';
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      position: relative;
      top: 8px;
      margin-right: 15px;
    }

    @media ${breakpoints.smartphones} {
      font-size: 60px;
      width: 80%;
    }

    @media ${breakpoints.iphoneLandscape} {
      background-position: left 21px;
      padding: 20px 0px 28px;
      background-size: 21% auto;
      font-size: 45px;
    }

    span {
      position: relative;
      top: 3px;

      @media ${breakpoints.iphoneLandscape} {
        top: 4px;
      }
    }
  }
`;

export const InformationBlock = styled.section`
  margin: 0px auto;
  max-width: 780px;

  .dealer-information {
    background: url(${CarIcon}) no-repeat left center transparent;
  }

  .dealer-information {
    background-size: 22% auto;
    vertical-align: middle;
    padding-left: 70px;
    margin: 0px auto;
    align: center;
    width: 260px;

    .dealer-information-inner {
      border-left: solid 3px ${colors['sky-blue']};
      padding-left: 12px;
    }

    .csz,
    .address {
      display: block;
    }

    .dealer {
      ${copyStyle(fonts.Book, '16px', 'bold', 'black', '18px')}
      margin: 0px 0px 5px;
    }

    p,
    p a {
      color: 'black';
    }

    p a {
      text-decoration: none;
    }

    p {
      ${copyStyle(fonts.Book, '14px', 'normal', 'black', '20px')}
    }

    .phone a {
      font-weight: 800;
      color: black;
    }
  }
`;
