import {createGlobalStyle} from 'styled-components';

import BoldEOT from '../fonts/VWHeadWeb-Bold.eot';
import BoldTTF from '../fonts/VWHeadWeb-Bold.ttf';
import BoldSVG from '../fonts/VWHeadWeb-Bold.svg';
import BoldWOFF from '../fonts/VWHeadWeb-Bold.woff';

import RegularTextEOT from '../fonts/VWTextWeb-Regular.eot';
import RegularTextTTF from '../fonts/VWTextWeb-Regular.ttf';
import RegularTextSVG from '../fonts/VWTextWeb-Regular.svg';
import RegularTextWOFF from '../fonts/VWTextWeb-Regular.woff';

import BoldTextEOT from '../fonts/VWTextWeb-Bold.eot';
import BoldTextTTF from '../fonts/VWTextWeb-Bold.ttf';
import BoldTextSVG from '../fonts/VWTextWeb-Bold.svg';
import BoldTextWOFF from '../fonts/VWTextWeb-Bold.woff';

export default createGlobalStyle`
  @font-face {
    font-family: "VWHeadWeb-Bold";
    src: url("${BoldTTF}") format("truetype"),
      url("${BoldEOT}") format("embedded-opentype"),
      url("${BoldSVG}#VWHeadWeb-Bold") format("svg"),
      url("${BoldWOFF}") format("woff");
  }
  @font-face {
    font-family: "VWTextWeb-Regular";
    src: url("${RegularTextTTF}") format("truetype"),
      url("${RegularTextEOT}") format("embedded-opentype"),
      url("${RegularTextSVG}#VWHeadWeb-Bold") format("svg"),
      url("${RegularTextWOFF}") format("woff");
  }
  @font-face {
    font-family: "VWTextWeb-Bold";
    src: url("${BoldTextTTF}") format("truetype"),
      url("${BoldTextEOT}") format("embedded-opentype),
      url("${BoldTextSVG}#VWHeadWeb-Bold") format("svg"),
      url("${BoldTextWOFF}") format("woff");
  }

  body {
    background-color: #D9D9D9;
    min-height: 100vh;
  }
`;
